*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100%;
    overflow: hidden;
    font-family: 'Montserrat';
    background: white;
    
}

.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline:none;
    mix-blend-mode:multiply;
    /* background-blend-mode: difference; */
}

h1{
    font-size: 8rem;
    color: white;
    text-transform: uppercase;
}

#darkmodebutton{
   position:absolute;
   z-index: 1 !important;
   width: 10vh;
   right:3%;
   top: 1%;
   height: 4%;
}
#darkMode{
    cursor: pointer;
}
#darkmodebutton :hover{
    filter: brightness(100);
    /* background: gray; */
}
#darkModeTrue{
    visibility: hidden;
    display: none;
}
#darkMode{
    background:none;
    border: none;
    height: 100%;
}
#darkMode img{
    width: 100%;
}

.container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    color: black;
    /* background-color: white; */
    
}
.container button{
    z-index: 1;
    mix-blend-mode:difference; 
    width: 100%;
    background:none;
    border: none;
    cursor: pointer;
}
.container img{
    z-index: 1;
    mix-blend-mode:difference; 
    width: 100%;
    height: auto;
    margin: auto;
    display: block;
    /* background-blend-mode: difference; */
    opacity: 1;    
    /* filter: invert(0%) sepia(93%) saturate(28%) hue-rotate(121deg) brightness(101%) contrast(107%); */
    filter:invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(107%) contrast(100%);    
}

section{
    /* height: ; */
}